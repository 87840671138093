<template>
  <v-card tile flat :loading="loading">
    <v-card-text v-if="solicitacoesServicos.length > 0" class="pt-6">
      <v-card
        class="mb-3"
        v-for="(solicitacao, i) in solicitacoesServicos"
        :key="i"
        flat
        outlined
      >
        <v-card-text class="grey lighten-5">
          <div class="px-6 py-2 d-flex align-center justify-center">
            <span class="text-subtitle-1">
              {{ solicitacao.nome_usuario }}
            </span>
            <v-spacer></v-spacer>
            {{ solicitacao.created_at | dateFormat("dd/MM/yyyy  HH:mm:ss") }}
          </div>
          <v-divider> </v-divider>
          <div class="pa-6">
            {{ solicitacao.solicitacao }}
          </div>

          <v-card-actions class="grey lighten-5 pa-4">
            <div v-for="(midia, j) in solicitacao.midias" :key="j">
              <v-btn
                icon
                v-if="midia.tipo == 'pdf'"
                :href="midia.url"
                target="_blank"
              >
                <v-icon> mdi-file-pdf-box </v-icon>
              </v-btn>
              <v-btn
                icon
                v-else-if="midia.tipo == 'jpg' || midia.tipo == 'png'"
                :href="midia.url"
                target="_blank"
              >
                <v-icon> mdi-image </v-icon>
              </v-btn>
              <v-btn icon v-else :href="midia.url" target="_blank">
                <v-icon> mdi-file-document-outline </v-icon>
              </v-btn>
            </div>
            <v-spacer></v-spacer>
            <div>
              <!-- <span v-if="solicitacao.atividade_id" class="mr-3 green--text">
                <v-icon class="green--text">mdi-check</v-icon>
                ATIVIDADE CADASTRADA
              </span> -->

              <span v-if="solicitacao.status === 1" class="green--text">
                <v-icon class="green--text">mdi-check</v-icon>
                LIDO
              </span>
            </div>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-card-text>
    <v-card-text v-else> Nenhum solicitação enviado. </v-card-text>
    <DialogImage
      v-if="dialogImage"
      :dialogImage.sync="dialogImage"
      :item="image"
    />
  </v-card>
</template>

<script>
import { fetchSolicitacoesServicos } from "@/api/cliente/solicitacoes_servicos.js";
import { mapState } from "vuex";
export default {
  name: "EnviadasSolicitacoesServicos",
  data() {
    return {
      solicitacoesServicos: [],
      image: null,
      loading: true,
      dialogImage: false,
    };
  },
  computed: {
    ...mapState("Usuario", {
      user_id: (state) => state.usuario.id,
    }),
  },
  components: {
    DialogImage: () => import("./DialogImage.vue"),
  },
  methods: {
    openImage(item) {
      this.dialogImage = true;
      this.image = item;
    },
    getSolicitacoesAll() {
      this.loading = true;
      return fetchSolicitacoesServicos(`?user_id=${this.user_id}`)
        .then((response) => {
          this.solicitacoesServicos = response;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  async mounted() {
    await this.getSolicitacoesAll();
  },
};
</script>

<style></style>
